<template>
  <div>
    <el-upload
        class="uploader"
        :disabled="disabled"
        :class="{hidden: img_hidden, cursor: disabled}"
        :accept="upload_type"
        :limit="limit"
        :file-list="fileList"
        :headers="header"
        :action="getApiUrl() + url"
        :data="{...upload_data, ...commonData}"
        :list-type="list_type"
        :before-upload="checkFormat"
        :on-change="changeImg"
        :on-preview="preview"
        :on-remove="removeImg"
        :on-exceed="overLimit"
        :multiple="multiple"
    >
        <i slot="default" class="el-icon-plus uploader-icon"/>
<!--        <div slot="file" slot-scope="{file}">-->
<!--          <div class="uploader-preview-img">-->
<!--            <el-image class="img" :src="file.url" :preview-src-list="files"/>-->
<!--            <span class="icon-delete" @click="removeImg(file)">-->
<!--              <i class="el-icon-close close-icon"/>-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
    </el-upload>
    <el-image v-show="false" ref="imgPreview" :preview-src-list="previewList"/>
  </div>
</template>

<script>
  import { Upload } from 'element-ui'
  import { checkFormat } from '../../js/common/util'

  export default {
    name: 'upload', // 上传文件
    components: {
      [Upload.name]: Upload,
    },
    data() {
      return {
        header: this.$store.state.header,
        img_hidden: false,
        fileList: [],
        files: [],
        commonData: this.$store.state.commonParams,
        previewList: [],
      }
    },
    props: {
      url: {type: String, default: '/api/public/upload/img'}, // 上传地址
      upload_data: {type: Object, default: () => {return {}}}, // 上传时附带的额外参数
      upload_type: {type: String, default: '.jpeg,.jpg,.png,.gif'}, // 接受上传的文件类型（thumbnail-mode 模式下此参数无效
      list_type: {type: String, default: 'picture-card'},
      limit: {type: Number, default: 9}, // 上传数量限制
      preview_size: {type: [Number, String], default: 1000}, // 图片放大预览大小
      disabled: {type: Boolean, default: false},
      multiple: {type: Boolean, default: false}, // 是否多选
    },
    methods: {
      getApiUrl() {
        const request = {
          local: '/testapi',
          master: ''
        }
        const hostArr = ['localhost', '192.168.10.124']
        if (hostArr.includes(document.domain)) {
          return request.local
        } else {
          return request.master
        }
      },
      // 初始数组图片
      initImg(img) {
        if (img) {
          for (let v of img) {
            if (v) {
              this.files.push(v)
              this.fileList.push({ name: v, url: v })
              this.judgeImgHidden(false)
            }
          }
        }
      },
      // 图片格式验证
      checkFormat(file) {
        return checkFormat(file, 0.5)
      },
      // 上传文件 文件状态改变时的钩子
      changeImg(file) {
        switch (file.status) {
          case 'ready':
            // if (!checkFormat(file)) {
            //   this.judgeImgHidden(false)
            // } else {
            //   this.judgeImgHidden(true)
            // }
            break
          case 'success':
            if (file.response.status_code == 200) {
              this.files.push(file.response.data.url)
              this.judgeImgHidden(false)
              this.$emit('change', this.deepCopy(this.files))
            } else {
              this.$msg.topMessage(file.response.message)
              this.fileList = this.deepCopy(this.fileList)
              this.judgeImgHidden(false)
            }
            break
          case 'fail':
            this.$msg.topMessage('添加失败')
            this.fileList = this.deepCopy(this.fileList)
            this.judgeImgHidden(false)
            break
        }
      },
      // 判断是否隐藏上传按钮
      judgeImgHidden(bool) {
        this.img_hidden = bool || this.files.length === this.limit
      },
      // 预览
      preview(file) {
        // this.$refs.imgPreview.open(file.url, this.preview_size, this.files)
        var url = file.response && file.response.data && file.response.data.url ? file.response.data.url : file.url
        let index = this.files.findIndex((val) => {
          return val === url
        })
        if (index === 0) {
          this.previewList = this.files
          this.$refs.imgPreview.showViewer = true
        } else if (index !== -1) {
          var start = this.files.slice(index)
          var end = this.files.slice(0, index)
          this.previewList = start.concat(end)
          this.$refs.imgPreview.showViewer = true
        }
      },
      // 删除图片
      removeImg(file) {
        var url = file.response && file.response.data && file.response.data.url ? file.response.data.url : file.url
        if (file.status === 'success') {
          let index = this.files.findIndex((val) => {
            return val === url
          })
          if (index !== -1) {
            this.files.splice(index, 1)
            this.$emit('remove', url)
            this.judgeImgHidden(false)
          }
        }
      },
      // 文件超出个数限制时的钩子
      overLimit() {
        return this.$msg.topMessage(`上传图片不能超过 ${this.limit}张！`)
      },
      clear() {
        this.img_hidden = false
        this.fileList = []
        this.files = []
      }
    }
  }
</script>

<style lang="scss">
  .uploader .el-upload--picture-card {
    /*width: 148px;*/
    /*height: 148px;*/
  }
  .uploader .el-upload-list--picture-card .el-upload-list__item {
    /*width: 148px;*/
    /*height: 148px;*/
    display: inline-flex;
    align-items: center;
    float: left;
  }
  .uploader .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 100%;
    height: auto;
  }
  .uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    text-align: center;
    /*width: 148px;*/
    /*height: 148px;*/
    /*line-height: 148px;*/
  }
  .hidden .el-upload--picture-card {
    display: none;
  }
  .cursor .el-upload--picture-card {
    cursor: no-drop;
  }
  .uploader-preview-img {
    position: relative;
    width: 148px;
    height: 148px;
    .img {
      width: 100%;
      height: 100%;
    }
    .icon-delete {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 0 6px 0 90%;
      background: #323233;
      color: #fff;
      .close-icon {
        display: inline-block;
        padding: 1px 1px 0 0;
        color: #fff;
        font-size: 14px;
      }
    }
  }
</style>
