export default {
  data() {
    return {
      height: 0,
      pagination: {
        page_num: 1,
        page_size: 50,
        total_num: 0,
      },
      columns: [],
      tableData: [],
      range: '',
      start_date: '',
      end_date: '',
      keyword: '',
    }
  },
  watch: {
    'pagination.page_num'(val, oldVal) {
      if (val != oldVal) this.loadData()
    },
    'pagination.page_size'(val, oldVal) {
      if (val != oldVal) this.loadData()
    },
  },
  created() {
    this.createdFun()
  },
  activated() {
    this.activatedFun()
  },
  methods: {
    createdFun() {
      this.$nextTick(() => {
        this.init()
        this.height = this.autoTableHeight()
      })
    },
    activatedFun() {
      if (!this.propName || !this.propName.id) return
      let query = this.$route.query
      let id = query[this.propName.id]
      if (!id) return
      this.$nextTick(() => {
        this.openDetail({[this.propName.id]: id})
        // delete this.$route.query[this.propName.id]
        this.$router.replace({ query: {} })
      })
    },
    // 查询
    query() {
      this.pagination.page_num = 1
      this.loadData()
    },
    // 筛选时间段
    changeRange(range) {
      this.range = this.deepCopy(range)
      this.start_date = this.range[0]
      this.end_date = this.range[1]
    },
    // 处理数据
    handleFun(row, url, text) {
      var that = this
      that.$msg.confirmBox(`确认${text}?`, '提示', () => {
        var postData = {
          [that.propName.id]: row[that.propName.id]
        }
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.$msg.topMessage(`${text}成功！`, 'success')
            that.loadData()
          }
        })
      })
    },
    // 删除
    del(row) {
      this.handleFun(row, this.postUrl.del, '删除')
    },
    // 打开添加/编辑弹窗
    openAdd(row, ref = this.$refs.add) {
      ref.dialog = true
      if (row) {
        this.$nextTick(() => {
          ref.form = this.setField(ref.form, row)
        })
      }
    },
    // 打开编辑， 非弹窗
    openDetail(row, ref = this.$refs.detail) {
      ref.dialog = true
      this.$nextTick(() => {
        if (row) ref.form = this.setField(ref.form, row)
        ref.open()
      })
    },
    // 上下架  status 1正常 0下架
    changeStatus(id, status) {
      var that = this
      that.$msg.confirmBox(`确认${status?'上架':'下架'}?`, '提示', () => {
        var url = that.postUrl.status
        var postData = {
          [that.propName.id]: id,
          [that.propName.status]: status,
        }
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.$msg.topMessage(`${status?'上架':'下架'}成功！`, 'success')
            that.loadData()
          }
        })
      })
    },
  }
}
