<template>
  <el-dialog
    title="请选择地图定位"
    :before-close="close"
    :visible.sync="dialog"
    @opened="open"
    append-to-body
    top="8vh"
    class="small-dialog"
    width="70%"
    :close-on-click-modal="false">
    <el-form ref="form" :model="form" class="base-form" :label-width="label_width" :show-message="false">
      <div class="mapBox">
        <div class="searchBox">
          <el-input
            style="width:250px;"
            placeholder="请输入关键字"
            suffix-icon="el-icon-search"
            @input="searchByKeyword"
            @focus="searchByKeyword"
            v-model="keyword"
          />
          <div class="search-result" v-if="searchResult.length && isShowResult">
            <div class="result-item" v-for="item of searchResult" :key="item.id" @click="handleClickResult(item)">
              <div class="area-name">{{ item.name }}</div>
              <div class="area-address">{{ item.district + item.address }}</div>
            </div>
          </div>
        </div>
        <div id="container" style="width: 100%;height: 400px;"/>
      </div>
      <el-form-item class="complex-form" label-width="-20px">
        <el-form-item v-show="false" prop="name"/>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="address" label="地址" required>
              <el-input :disabled="true" v-model="form.address" placeholder="地址"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="longitude" label="经度" required>
              <el-input :disabled="true" v-model="form.longitude" placeholder="经度"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="latitude" label="纬度" required>
              <el-input :disabled="true" v-model="form.latitude" placeholder="纬度"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <submit-button @close="close" @save="save"/>
  </el-dialog>
</template>

<script>
  import AMapLoader from '@amap/amap-jsapi-loader'

  window._AMapSecurityConfig = {
    securityJsCode: 'd22dcfc76786a99f8328697ea014ad5c' //填写你的安全密钥
  }

  export default {
    name: 'mapSelect', // 高德地图选点获取经纬度
    data() {
      return {
        dialog: false,
        label_width: '70px',
        map: null,
        mapInit: {
          key: '24b1103b6b1058bcd0cdab28c8db4997', //申请好的Web端开发者Key
          longitude: 118.146948, //初始化地图中心点位置
          latitude: 24.485846, //初始化地图中心点位置
          icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
        },
        form: {
          name: '',
          address: '',
          longitude: '',
          latitude: '',
        },
        marker: null,
        autocomplete: null,
        placeSearch: null, //搜索框
        keyword: '',
        searchResult: [],
        isShowResult: false
      }
    },
    methods: {
      open() {
        this.initMap()
      },
      close() {
        this.dialog = false
        this.$refs.form.resetFields()
        this.map = null
        this.marker = null
        this.autocomplete = null
        this.placeSearch = null
        this.keyword = ''
        this.searchResult = []
        this.isShowResult = false
      },
      // 点击地图
      onMapClick(e) {
        let that = this
        that.keyword = ''
        that.form.name = ''
        that.form.address = ''
        that.form.latitude = e.lnglat.lat
        that.form.longitude = e.lnglat.lng
        that.isShowResult = false
        if (that.map.getAllOverlays('marker').length) {
          // 地图已有标注，清除标注
          that.map.clearMap()
        }
        that.setMarker(that.form.name)
        // 点击地图上的位置，根据经纬度转换成详细地址
        that.map.plugin(['AMap.Geocoder'], function () {
          let geocoder = new AMap.Geocoder({})
          that.lnglat = [that.form.longitude, that.form.latitude]
          geocoder.getAddress(that.lnglat, function (status, result) {
            if (status === 'complete' && result.regeocode) {
              that.form.address = result.regeocode.formattedAddress
            }
          })
        })
      },
      // 地图初始化
      initMap() {
        AMapLoader.reset()
        AMapLoader.load({
          key: this.mapInit.key, // 申请好的Web端开发者Key，首次调用 load 时必填
          version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.AutoComplete', 'AMap.PlaceSearch', 'AMap.MarkerClusterer', 'AMap.DistrictSearch', 'AMap.ZoomControl', 'AMap.Polygon'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
          let center = []
          if (this.form.longitude && this.form.latitude) {
            center = [this.form.longitude, this.form.latitude]
            setTimeout(() => {
              this.setMarker()
            }, 500)
          } else {
            center = [this.mapInit.longitude, this.mapInit.latitude]
          }
          this.map = new AMap.Map('container', { //设置地图容器id
            // viewMode: "3D",    //是否为3D地图模式
            resizeEnable: true,
            zoom: 12, //初始化地图级别
            center: center, //初始化地图中心点位置
          })
          this.map.on('click', (e) => {
            this.onMapClick(e)
          })
          var autoOptions = {
            input: 'tipinput'
          }
          this.autocomplete = new AMap.AutoComplete(autoOptions)
          this.placeSearch = new AMap.PlaceSearch({
            map: this.map
          })
        }).catch(e => {
          console.log(e)
        })
      },
      // 设置标记点
      setMarker(name) {
        this.marker = new AMap.Marker({
          icon: this.mapInit.icon,
          position: [this.form.longitude, this.form.latitude],
          zIndex: 103
        })
        if (name) {
          // let num = name.length * 4
          this.marker.setLabel({
            // offset: new AMap.value.Pixel(-num, 35),
            content: name
          })
        }
        this.map.add(this.marker)
      },
      changeValue(longitude, latitude, name, address) {
        this.isShowResult = false
        this.form.name = name
        this.form.address = address
        this.form.longitude = longitude
        this.form.latitude = latitude
        const center = new AMap.LngLat(longitude, latitude)
        // 将地图的中心点移动到指定的经纬度
        this.map.setCenter(center)
        this.setMarker(this.form.name)
      },
      // 点击搜索结果
      handleClickResult(e) {
        const {location, district, address} = e
        const {lng, lat} = location || {}
        // 1. 如果当前搜索结果有经纬度, 直接在地图上选中, 并返回当前的值
        if (lng && lat) {
          this.map.clearMap() //先清空点位
          this.changeValue(lng, lat, e.name, district + address)
        } else {
          this.isShowResult = false
          this.placeSearch.search(district + address)
        }
      },
      // 输入框搜索显示下拉
      searchByKeyword() {
        this.autocomplete.search(this.keyword, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            this.searchResult = result.tips.map((item) => {
              return {
                ...item,
                address: Array.isArray(item.address) ? (item.address[0] || '') : item.address
              }
            })
            this.isShowResult = true
          }
        })
      },
      save() {
        if (this.form.latitude && this.form.longitude) {
          this.$emit('setLoc', this.form)
          this.close()
        } else {
          this.$msg.topMessage('请选择经纬度')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mapBox {
    position: relative;
    margin-bottom: 20px;
    .searchBox {
      position: absolute;
      z-index: 111;
      top: 10px;
      left: 10px;
    }
  }
  .search-result {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.0600);
    box-sizing: border-box;
    .result-item {
      width: 100%;
      cursor: pointer;
      border-bottom: 2px solid #eee;
      padding: 10px 12px;
      .area-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 20px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 20px;
      }
      .area-address {
        color: rgba(0, 0, 0, 0.4500);
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
</style>
