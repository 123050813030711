<template>
  <div class="login-box">
    <img class="logo" src="../../assets/logo.png" alt="">
<!--    <div class="welcome-text">Hi! 欢迎登录</div>-->
    <div class="welcome-text">飞特必吃清单管理系统</div>
    <div style="width: 300px; margin: 0 auto;">
      <div class="input-box tel">
        <div>+86</div>
        <input v-model="mobile" type="number" placeholder="输入手机号码">
<!--        <input v-model="mobile" type="number" placeholder="输入手机号码" @keyup.enter="loginIn">-->
      </div>
      <div class="input-box">
        <div>密码</div>
        <input v-model="password" type="password" placeholder="输入密码">
      </div>
    </div>
    <div class="login-btn" @click="loginIn">登录</div>
    <div class="record-number">琼ICP备19004478号-1</div>
  </div>
</template>

<script>
  import md5 from 'js-md5'

  export default {
    name: 'login',
    data() {
      return {
        mobile: '',
        password: '',
        encipherPsw: '', // 经过md5加密的密码
        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, // 手机号码正则表达式
      }
    },
    mounted() {
      this.autoLogin()
      window.addEventListener('keydown', this.keyDown)
    },
    destroyed() {
      window.removeEventListener('keydown', this.keyDown, false)
    },
    methods: {
      autoLogin() {
        let url = location.search // 获取url中"?"符后的字串
        if (url) {
          url = url.substring(1)
          let urlList = url.split(',')
          this.mobile = urlList[0]
          this.encipherPsw = urlList[1]
          this.loginIn()
        }
      },
      loginIn() {
        var that = this
        if (!that.pattern.test(that.mobile)) return that.$msg.topMessage('请输入正确的电话号码！')
        if (!that.password && !that.encipherPsw) return that.$msg.topMessage('请输入密码！')
        var url = '/user/mobile_login'
        var postData = {
          mobile: that.mobile,
          password: that.encipherPsw ? md5(that.mobile+that.encipherPsw) : md5(that.mobile+md5(that.password)),
        }
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200) {
            that.$msg.topMessage('登录成功！', 'success')
            that.$store.commit('set_token', res.data.token)
            that.$store.commit('set_user_info', {user_id: res.data.user_id, user_name: res.data.user_name})
            that.$router.replace({name: that.$store.state.homeRouter})
            // that.$store.commit('set_platform_list', res.data.platform_list)
            // that.$router.replace({name: 'switchAccount'})
          }
        })
      },
      // 点击回车键登录
      keyDown(e) {
        // enter键的ASCII是13，108小键盘回车键
        if (e.keyCode == 13 || e.keyCode == 108) {
          this.loginIn()
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .login-box {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    padding: 0 46px;
    text-align: center;
    background: #fff;
    overflow-x: hidden;
    box-sizing: border-box;
    .logo {
      width: 74px;
      height: 74px;
      margin: 71px 0 26px;
    }
    .welcome-text {
      margin-bottom: 57px;
      font-weight: 600;
      font-size: 19px;
      line-height: 26px;
      letter-spacing: 0.035em;
      color: #000000;
    }
    .input-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      margin-top: 20px;
      border-bottom: 1px solid #F7F7F7;
      >div {
        width: 47px;
        height: 24px;
        margin-right: 26px;
        font-size: 15px;
        line-height: 24px;
        color: #999;
      }
      input {
        font-size: 15px;
        line-height: 24px;
        color: #000;
        text-align: left;
      }
      input::placeholder {
        color: #D3D3D3;
      }
    }
    .input-box.tel>div {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.015em;
      color: #000000;
      border-right: 1px solid #F7F7F7;
    }
    .login-btn {
      width: 283px;
      height: 45px;
      margin: 71px auto 0;
      background: #0075FF;
      font-weight: 600;
      font-size: 14px;
      line-height: 45px;
      text-align: center;
      color: #FFFFFF;
      border-radius: 22px;
    }
    .record-number {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 20px;
      font-weight: 400;
      font-size: 13px;
      color: #999;
    }
  }
</style>
