<template>
  <el-dialog
    :title="title"
    :before-close="close"
    :visible.sync="dialog"
    @opened="open"
    append-to-body
    top="8vh"
    class="small-dialog"
    width="450px"
    :close-on-click-modal="false">
    <el-form :model="form" ref="form" class="base-form" :label-width="label_width" :show-message="false">
      <el-form-item v-show="false" prop="article_id"/>
      <el-form-item v-show="false" prop="imgList1"/>
      <el-form-item prop="article_name" label="文章标题" required>
        <el-input v-model="form.article_name" placeholder="请输入文章标题"/>
      </el-form-item>
      <el-form-item prop="article_desc" label="文章描述" required>
        <el-input type="textarea" placeholder="请输入文章描述" v-model="form.article_desc" :autosize="{minRows: 2, maxRows: 4}" maxlength="500" show-word-limit/>
      </el-form-item>
      <el-form-item prop="article_img" label="文章图片" required>
        <upload ref="upload1" :limit="1" @change="(file) => {changeImg(file, 1)}" @remove="(url) => {removeImg(url, 1)}"/>
      </el-form-item>
      <el-form-item prop="article_link" label="文章链接" required>
        <el-input v-model="form.article_link" placeholder="请输入文章链接"/>
      </el-form-item>
    </el-form>
    <submit-button @close="close" @save="save"/>
  </el-dialog>
</template>

<script>
  import upload from '../../components/tool/upload'

  export default {
    name: 'addArticleSettings',
    components: { upload },
    data() {
      return {
        title: '',
        dialog: false,
        label_width: '80px',
        postUrl: {
          add: '/article/add',
          edit: '/article/edit',
        },
        form: {
          article_id: '',
          article_name: '',
          article_desc: '',
          article_img: '',
          imgList1: [],
          article_link: '',
        },
        imgProps: ['article_img'],
      }
    },
    methods: {
      open() {
        if (this.form.article_id) {
          this.title = '编辑文章'
          this.imgProps.forEach((item, index) => {
            if (this.form[item]) {
              this.form[`imgList${index+1}`] = this.form[item].split(',')
              this.$refs[`upload${index+1}`].initImg(this.form[`imgList${index+1}`])
            }
          })
        } else {
          this.title = '添加文章'
        }
      },
      close() {
        this.title = ''
        this.$refs.upload1.clear()
        this.$refs.form.resetFields()
        this.dialog = false
      },
      changeImg(file, type = 1) {
        this.form[`imgList${type}`] = this.deepCopy(file)
        this.form[this.imgProps[type - 1]] = this.form[`imgList${type}`].join()
      },
      removeImg(url, type = 1) {
        let index = this.form[`imgList${type}`].findIndex((val) => {
          return val === url
        })
        if (index !== -1) {
          this.form[`imgList${type}`].splice(index, 1)
          this.form[this.imgProps[type - 1]] = this.form[`imgList${type}`].join()
        }
      },
      save() {
        var that = this
        if (!that.form.article_name) return that.$msg.topMessage('请输入文章标题')
        if (!that.form.article_desc) return that.$msg.topMessage('请输入文章描述')
        if (!that.form.article_img) return that.$msg.topMessage('请选择文章图片')
        if (!that.form.article_link) return that.$msg.topMessage('请输入文章链接')
        var url = that.form.article_id ? that.postUrl.edit : that.postUrl.add
        var postData = that.form
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.$msg.topMessage(`${that.form.article_id ? '编辑' : '添加'}成功！`, 'success')
            that.close()
            that.$emit('refresh')
          }
        })
      }
    }
  }
</script>
