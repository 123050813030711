<template>
  <div>
    <div class="tool-bar">
      <add-button @add="openDetail"/>
      <select-list ref="select1" :url="postUrl.select1" label_name="星级" placeholder="请选择星级" :select_id.sync="res_star_id" select_width="160px" value="res_star_id" label="res_star_name"/>
      <select-list ref="select2" :url="postUrl.select2" label_name="场景" placeholder="请选择场景" :select_id.sync="scenes_id" select_width="160px" value="scenes_id" label="scenes_name"/>
      <select-list ref="select3" :url="postUrl.select3" label_name="菜系" placeholder="请选择菜系" :select_id.sync="dish_style_id" select_width="160px" value="dish_style_id" label="dish_style_name"/>
      <select-list ref="select4" :url="postUrl.select4" label_name="区域" placeholder="请选择区域" :select_id.sync="area_id" select_width="160px" value="area_id" label="area_name"/>
      <select-list ref="select5" :url="postUrl.select5" label_name="商圈" placeholder="请选择商圈" :select_id.sync="shop_area_id" select_width="160px" value="shop_area_id" label="shop_area_name"/>
      <search-input :keyword.sync="keyword" placeholder="商家名称"/>
      <text-button @clickBtn="query"/>
      <text-button type="danger" name="清除" @clickBtn="clearData"/>
    </div>
    <el-table v-tableFit ref="table" :data="tableData" size="mini" border :height="height">
      <el-table-column type="index" align="center" fixed/>
      <el-table-column label="操作" align="center" width="100" fixed>
        <template slot-scope="scope">
          <el-button type="text" @click="openDetail(scope.row)">编辑</el-button>
          <el-button type="text" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column v-for="(col, index) in columns" :prop="col.prop" :label="col.label" :key="index" header-align="center" show-overflow-tooltip :align="col.align?col.align:'left'" :width="col.width?col.width:''">
        <template slot-scope="scope">
          <span>{{scope.row[col.prop]}}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination :page.sync="pagination.page_num" :size.sync="pagination.page_size" :total="pagination.total_num"/>
    <detail ref="detail" @refresh="loadData"/>
  </div>
</template>

<script>
  import detail from './detail'
  import mixinBaseIndex from '../../js/mixins/mixinBaseIndex'

  export default {
    name: 'business', // 商家管理
    components: { detail },
    mixins: [ mixinBaseIndex ],
    data() {
      return {
        propName: {
          id: 'res_id'
        },
        postUrl: {
          list: '/restaurant/list',
          del: '/restaurant/del',
          select1: '/restaurant/res_star_list',
          select2: '/restaurant/scenes_list',
          select3: '/restaurant/dish_style_list',
          select4: '/restaurant/area_list',
          select5: '/restaurant/shop_area_list'
        },
        columns: [
          {prop: 'res_name', label: '商家名称'},
          {prop: 'res_star_name', label: '星级'},
          {prop: 'scenes_names', label: '场景'},
          {prop: 'dish_style_name', label: '菜系'},
          {prop: 'area_name', label: '区域'},
          {prop: 'shop_area_name', label: '商圈'},
          {prop: 'business_time', label: '营业时间', width: 200},
          {prop: 'res_addr', label: '地址', width: 400},
          {prop: 'created_at', label: '创建时间'},
        ],
        res_star_id: '', // 星级id
        scenes_id: '', // 场景id
        dish_style_id: '', // 菜系id
        area_id: '', // 地区id
        shop_area_id: '', // 商圈id
      }
    },
    methods: {
      init() {
        this.$refs.select1.getList()
        this.$refs.select2.getList()
        this.$refs.select3.getList()
        this.$refs.select4.getList()
        this.$refs.select5.getList()
        this.loadData()
      },
      loadData() {
        var that = this
        var url = that.postUrl.list
        var postData = {
          res_star_id: that.res_star_id,
          scenes_id: that.scenes_id,
          dish_style_id: that.dish_style_id,
          area_id: that.area_id,
          shop_area_id: that.shop_area_id,
          search_key: that.keyword,
          page_num: that.pagination.page_num,
          page_size: that.pagination.page_size,
        }
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.tableData = res.data
            that.pagination.total_num = res.total_num
          }
        })
      },
      clearData() {
        this.$refs.select1.clear()
        this.$refs.select2.clear()
        this.$refs.select3.clear()
        this.$refs.select4.clear()
        this.$refs.select5.clear()
        this.keyword = ''
      }
    }
  }
</script>
