<template>
  <el-dialog
    :title="title"
    :before-close="close"
    :visible.sync="dialog"
    @opened="open"
    append-to-body
    top="8vh"
    class="small-dialog"
    width="450px"
    :close-on-click-modal="false">
    <el-form :model="form" ref="form" class="base-form" :label-width="label_width" :show-message="false">
      <el-form-item v-show="false" prop="module_id"/>
      <el-form-item v-show="false" prop="imgList1"/>
      <el-form-item prop="module_name" label="模块标题" required>
        <el-input v-model="form.module_name" placeholder="请输入模块标题"/>
      </el-form-item>
      <el-form-item prop="module_desc" label="模块描述" required>
        <el-input type="textarea" placeholder="请输入模块描述" v-model="form.module_desc" :autosize="{minRows: 2, maxRows: 4}" maxlength="500" show-word-limit/>
      </el-form-item>
      <el-form-item prop="module_icon" label="模块icon" required>
        <upload ref="upload1" :limit="1" @change="(file) => {changeImg(file, 1)}" @remove="(url) => {removeImg(url, 1)}"/>
      </el-form-item>
      <el-form-item prop="jump_way" label="跳转方式" required>
        <select-list ref="select1" :select_list="selectList" placeholder="请选择跳转方式" :select_id.sync="form.jump_way" :show_label="false" select_width="100%" margin_bottom="0" :clearable="false"/>
      </el-form-item>
      <el-form-item prop="jump_url" label="跳转地址" required>
        <el-input v-model="form.jump_url" placeholder="请输入跳转地址"/>
      </el-form-item>
    </el-form>
    <submit-button @close="close" @save="save"/>
  </el-dialog>
</template>

<script>
  import upload from '../../components/tool/upload'

  export default {
    name: 'addDiamondsSetting',
    components: { upload },
    data() {
      return {
        title: '',
        dialog: false,
        label_width: '80px',
        postUrl: {
          add: '',
          edit: '/module/edit',
        },
        form: {
          module_id: '',
          module_name: '',
          module_desc: '',
          module_icon: '',
          imgList1: [],
          jump_way: '',
          jump_url: '',
        },
        imgProps: ['module_icon'],
        selectList: [
          {id: 1, name: '本小程序'},
          // {id: 2, name: '外部小程序'},
          {id: 3, name: '外部H5'},
        ],
      }
    },
    methods: {
      open() {
        if (this.form.module_id) {
          this.title = '编辑模块'
          this.imgProps.forEach((item, index) => {
            if (this.form[item]) {
              this.form[`imgList${index+1}`] = this.form[item].split(',')
              this.$refs[`upload${index+1}`].initImg(this.form[`imgList${index+1}`])
            }
          })
          this.$nextTick(() => {
            this.$refs.select1.init()
          })
        } else {
          this.title = '添加模块'
        }
      },
      close() {
        this.title = ''
        this.$refs.upload1.clear()
        this.$refs.select1.clear()
        this.$refs.form.resetFields()
        this.dialog = false
      },
      changeImg(file, type = 1) {
        this.form[`imgList${type}`] = this.deepCopy(file)
        this.form[this.imgProps[type - 1]] = this.form[`imgList${type}`].join()
      },
      removeImg(url, type = 1) {
        let index = this.form[`imgList${type}`].findIndex((val) => {
          return val === url
        })
        if (index !== -1) {
          this.form[`imgList${type}`].splice(index, 1)
          this.form[this.imgProps[type - 1]] = this.form[`imgList${type}`].join()
        }
      },
      save() {
        var that = this
        if (!that.form.module_name) return that.$msg.topMessage('请输入模块标题')
        if (!that.form.module_desc) return that.$msg.topMessage('请输入模块描述')
        if (!that.form.module_icon) return that.$msg.topMessage('请选择模块icon')
        if (!that.form.jump_way) return that.$msg.topMessage('请选择跳转方式')
        if (!that.form.jump_url) return that.$msg.topMessage('请输入跳转地址')
        var url = that.form.module_id ? that.postUrl.edit : that.postUrl.add
        var postData = that.form
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.$msg.topMessage(`${that.form.module_id ? '编辑' : '添加'}成功！`, 'success')
            that.close()
            that.$emit('refresh')
          }
        })
      }
    }
  }
</script>
