<template>
  <div class="edit-page edit-base-product" v-if="dialog">
    <!-- 编辑 头部 -->
    <div class="edit-header">
      <div>
        <text-button name="保存" margin_bottom="0" @clickBtn="save"/>
      </div>
      <span>{{title}}</span>
      <div/>
      <i class="el-icon-close close-icon" @click="close" />
    </div>
    <!-- 编辑 内容 -->
    <div class="edit-content">
      <el-form :model="form" ref="form" class="base-form" :label-width="label_width" :show-message="false">
        <el-form-item v-show="false" prop="res_id"/>
        <el-form-item v-show="false" prop="person_cost_down"/>
        <el-form-item v-show="false" prop="person_num_down"/>
        <el-form-item v-show="false" prop="imgList1"/>
        <el-form-item v-show="false" prop="imgList2"/>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="res_name" label="商家名称" required>
                <el-input v-model="form.res_name" placeholder="请输入商家名称"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="res_star_id" label="星级" required>
                <select-list ref="select1" :url="postUrl.select1" placeholder="请选择星级" :select_id.sync="form.res_star_id" :show_label="false" value="res_star_id" label="res_star_name" select_width="100%" margin_bottom="0"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="scenes_ids" label="场景" required>
                <select-list ref="select2" :url="postUrl.select2" placeholder="请选择场景" :select_id.sync="form.scenes_ids" :multiple="true" :show_label="false" value="scenes_id" label="scenes_name" select_width="100%" margin_bottom="0"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="dish_style_id" label="菜系" required>
                <select-list ref="select3" :url="postUrl.select3" placeholder="请选择菜系" :select_id.sync="form.dish_style_id" :show_label="false" value="dish_style_id" label="dish_style_name" select_width="100%" margin_bottom="0"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="area_id" label="区域" required>
                <select-list ref="select4" :url="postUrl.select4" placeholder="请选择区域" :select_id.sync="form.area_id" :show_label="false" value="area_id" label="area_name" select_width="100%" margin_bottom="0"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="shop_area_id" label="商圈" required>
                <select-list ref="select5" :url="postUrl.select5" placeholder="请选择商圈" :select_id.sync="form.shop_area_id" :show_label="false" value="shop_area_id" label="shop_area_name" select_width="100%" margin_bottom="0"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="business_time" label="营业时间" required>
                <el-input v-model="form.business_time" placeholder="请输入营业时间"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="park_ind" label="是否可停车" required>
                <el-radio-group v-model="form.park_ind">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="compartment" label="是否有包厢" required>
                <el-radio-group v-model="form.compartment">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="res_addr" label="地址" required>
                <el-input v-model="form.res_addr" placeholder="请选择地址" @focus="openMap"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="longitude" label="经度" required>
                <el-input :disabled="true" v-model="form.longitude" placeholder="经度"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="latitude" label="纬度" required>
                <el-input :disabled="true" v-model="form.latitude" placeholder="纬度"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="person_cost" label="人均" required>
                <el-input-number v-model="form.person_cost_down" placeholder="请输入人均下限" :step="1" :min="0" :precision="2" :controls="false" class="input-class"/>
                <span class="input-space">-</span>
                <el-input-number v-model="form.person_cost" placeholder="请输入人均上限" :step="1" :min="0" :precision="2" :controls="false" class="input-class"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="person_num_up" label="适合人数" required>
                <el-input-number v-model="form.person_num_down" placeholder="请输入适合人数下限" :step="1" :min="0" :controls="false" class="input-class"/>
                <span class="input-space">-</span>
                <el-input-number v-model="form.person_num_up" placeholder="请输入适合人数上限" :step="1" :min="0" :controls="false" class="input-class"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="res_teli" label="联系电话" required>
                <el-input v-model="form.res_teli" placeholder="请输入联系电话"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="wechat" label="微信号" required>
                <el-input v-model="form.wechat" placeholder="请输入微信号"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="article_author" label="文章作者" required>
                <el-input v-model="form.article_author" placeholder="请输入文章作者"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="article_title" label="文章标题" required>
                <el-input v-model="form.article_title" placeholder="请输入文章标题"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="is_recommend" label="是否推荐商家" required>
                <el-radio-group v-model="form.is_recommend">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="article_link" label="文章链接" required>
                <el-input v-model="form.article_link" placeholder="请输入文章链接"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="description" label="推荐的一句话" required>
                <el-input type="textarea" v-model="form.description" placeholder="请输入推荐的一句话" :autosize="{minRows: 6}" maxlength="500" show-word-limit/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="res_img" label="餐厅列表图片" required>
                <upload ref="upload1" :limit="1" @change="(file) => {changeImg(file, 1)}" @remove="(url) => {removeImg(url, 1)}"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item prop="res_detail_img" label="餐厅详情图片" required>
                <upload ref="upload2" :limit="6" @change="(file) => {changeImg(file, 2)}" @remove="(url) => {removeImg(url, 2)}"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="complex-form" label-width="10px">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item prop="product_list" label="推荐菜品名称" required>
                <el-table v-if="isShowTable" v-tableFit ref="table" class="edit-table m-b-8px" :data="form.product_list" size="mini" border>
                  <el-table-column label="操作" align="center" width="70" fixed>
                    <template slot-scope="scope">
                      <el-button size="mini" type="danger" icon="el-icon-delete" @click="delProduct(scope.row, scope.$index)"/>
                    </template>
                  </el-table-column>
                  <el-table-column prop="product_name" label="菜品名称">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.product_name" placeholder="请输入菜品名称" maxlength="100" show-word-limit/>
                    </template>
                  </el-table-column>
                  <el-table-column prop="price" label="菜品价格">
                    <template slot-scope="scope">
                      <el-input-number v-model="scope.row.price" placeholder="请输入菜品价格" :step="1" :min="0" :precision="2" :controls="false"/>
                    </template>
                  </el-table-column>
                  <el-table-column prop="product_img" label="菜品图片">
                    <template slot-scope="scope">
                      <upload :ref="`upload${scope.$index+3}`" :limit="1" @change="(file) => {changeTableImg(file, scope.$index)}" @remove="(url) => {removeTableImg(url, scope.$index)}"/>
                    </template>
                  </el-table-column>
                </el-table>
                <el-button type="primary" size="mini" @click="openProduct">添加推荐菜品</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
    <map-select ref="mapSelect" @setLoc="setLoc"/>
  </div>
</template>

<script>
  import upload from '../../components/tool/upload'
  import mapSelect from '../../components/common/mapSelect'

  export default {
    name: 'addBusiness',
    components: { upload, mapSelect },
    data() {
      return {
        title: '',
        dialog: false,
        label_width: '100px',
        postUrl: {
          detail: '/restaurant/detail',
          add: '/restaurant/add',
          edit: '/restaurant/edit',
          select1: '/restaurant/res_star_list',
          select2: '/restaurant/scenes_list',
          select3: '/restaurant/dish_style_list',
          select4: '/restaurant/area_list',
          select5: '/restaurant/shop_area_list'
        },
        form: {
          res_id: '',
          res_name: '',
          res_star_id: '',
          scenes_ids: [],
          dish_style_id: '',
          area_id: '',
          shop_area_id: '',
          business_time: '',
          park_ind: '',
          compartment: '',
          res_addr: '',
          longitude: '',
          latitude: '',
          person_cost: '',
          person_cost_down: '',
          person_num_up: '',
          person_num_down: '',
          res_teli: '',
          wechat: '',
          article_author: '',
          article_title: '',
          is_recommend: '',
          article_link: '',
          description: '',
          res_img: '',
          imgList1: [],
          res_detail_img: '',
          imgList2: [],
          product_list: [],
        },
        imgProps: ['res_img', 'res_detail_img'],
        isShowTable: false,
      }
    },
    methods: {
      open() {
        if (this.form.res_id) {
          this.title = '编辑商家管理'
          this.loadData()
        } else {
          this.title = '添加商家管理'
          this.$refs.select1.getList()
          this.$refs.select2.getList()
          this.$refs.select3.getList()
          this.$refs.select4.getList()
          this.$refs.select5.getList()
          this.form.park_ind = 0
          this.form.compartment = 0
          this.form.is_recommend = 0
          this.isShowTable = true
        }
      },
      close() {
        this.title = ''
        for (let i = 1; i <= this.form.product_list.length + 2; i++) {
          this.$refs[`upload${i}`].clear()
        }
        this.$refs.select1.clear()
        this.$refs.select2.clear()
        this.$refs.select3.clear()
        this.$refs.select4.clear()
        this.$refs.select5.clear()
        this.$refs.form.resetFields()
        this.isShowTable = false
        this.dialog = false
      },
      loadData() {
        var that = this
        var url = that.postUrl.detail
        var postData = {
          res_id: that.form.res_id
        }
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            if (res.data.scenes_ids) res.data.scenes_ids = res.data.scenes_ids.split(',').map(Number)
            else res.data.scenes_ids = []
            that.form = that.setField(that.form, res.data)
            that.imgProps.forEach((item, index) => {
              if (that.form[item]) {
                that.form[`imgList${index+1}`] = that.form[item].split(',')
                that.$refs[`upload${index+1}`].initImg(that.form[`imgList${index+1}`])
              }
            })
            that.isShowTable = true
            that.$nextTick(() => {
              that.$refs.select1.getList()
              that.$refs.select2.getList()
              that.$refs.select3.getList()
              that.$refs.select4.getList()
              that.$refs.select5.getList()
              that.form.product_list.forEach((item, index) => {
                if (item.product_img) {
                  item.imgList = item.product_img.split(',')
                  that.$refs[`upload${index+3}`].initImg(item.imgList)
                }
              })
            })
          }
        })
      },
      changeImg(file, type = 1) {
        this.form[`imgList${type}`] = this.deepCopy(file)
        this.form[this.imgProps[type - 1]] = this.form[`imgList${type}`].join()
      },
      removeImg(url, type = 1) {
        let index = this.form[`imgList${type}`].findIndex((val) => {
          return val === url
        })
        if (index !== -1) {
          this.form[`imgList${type}`].splice(index, 1)
          this.form[this.imgProps[type - 1]] = this.form[`imgList${type}`].join()
        }
      },
      changeTableImg(file, type) {
        this.form.product_list[type].imgList = this.deepCopy(file)
        this.form.product_list[type].product_img = this.form.product_list[type].imgList.join()
      },
      removeTableImg(url, type) {
        let index = this.form.product_list[type].imgList.findIndex((val) => {
          return val === url
        })
        if (index !== -1) {
          this.form.product_list[type].imgList.splice(index, 1)
          this.form.product_list[type].product_img = this.form.product_list[type].imgList.join()
        }
      },
      openProduct() {
        let data = {
          product_name: '',
          price: '',
          product_img: '',
          imgList: [],
        }
        this.form.product_list.push(data)
      },
      delProduct(row, index) {
        this.form.product_list.splice(index, 1)
        // this.$msg.confirmBox('确认删除？', '提示', () => {
        //   this.form.product_list.splice(index, 1)
        // })
      },
      openMap() {
        let ref = this.$refs.mapSelect
        let data = this.deepCopy(this.form)
        data.name = data.res_name
        data.address = data.res_addr
        if (this.form.longitude && this.form.latitude) {
          ref.form = this.setField(ref.form, data)
        }
        ref.dialog = true
      },
      setLoc(data) {
        this.form.res_addr = data.address
        this.form.longitude = data.longitude
        this.form.latitude = data.latitude
      },
      save() {
        var that = this
        if (!that.form.res_name) return that.$msg.topMessage('请输入商家名称')
        if (!that.form.res_star_id) return that.$msg.topMessage('请选择星级')
        if (!that.form.scenes_ids.length) return that.$msg.topMessage('请选择场景')
        if (!that.form.dish_style_id) return that.$msg.topMessage('请选择菜系')
        if (!that.form.area_id) return that.$msg.topMessage('请选择区域')
        if (!that.form.shop_area_id) return that.$msg.topMessage('请选择商圈')
        if (!that.form.business_time) return that.$msg.topMessage('请输入营业时间')
        if (!that.form.res_addr) return that.$msg.topMessage('请选择地址')
        if (that.form.person_cost === undefined) return that.$msg.topMessage('请输入人均上限')
        if (that.form.person_cost_down === undefined) return that.$msg.topMessage('请输入人均下限')
        if (that.form.person_cost < that.form.person_cost_down) return that.$msg.topMessage('人均上限须大于或等于人均下限')
        if (that.form.person_num_up === undefined) return that.$msg.topMessage('请输入适合人数上限')
        if (that.form.person_num_down === undefined) return that.$msg.topMessage('请输入适合人数下限')
        if (that.form.person_num_up < that.form.person_num_down) return that.$msg.topMessage('适合人数上限须大于或等于适合人数下限')
        if (!that.form.res_teli) return that.$msg.topMessage('请输入联系电话')
        if (!that.form.wechat) return that.$msg.topMessage('请输入微信号')
        if (!that.form.article_author) return that.$msg.topMessage('请输入文章作者')
        if (!that.form.article_title) return that.$msg.topMessage('请输入文章标题')
        if (!that.form.article_link) return that.$msg.topMessage('请输入文章链接')
        if (!that.form.description) return that.$msg.topMessage('请输入推荐的一句话')
        if (!that.form.res_img) return that.$msg.topMessage('请选择餐厅列表图片')
        if (!that.form.res_detail_img) return that.$msg.topMessage('请选择餐厅详情图片')
        if (!that.form.product_list.length) return that.$msg.topMessage('请添加推荐菜品名称')
        let flag = that.form.product_list.some(i => {
          return !i.product_name || i.price === undefined || !i.product_img
        })
        if (flag) return that.$msg.topMessage('推荐菜品内容请补充完整')
        var url = that.form.res_id ? that.postUrl.edit : that.postUrl.add
        var postData = that.deepCopy(that.form)
        postData.scenes_ids = postData.scenes_ids.join()
        postData.product_list.forEach(i => {
          delete i.imgList
        })
        postData.product_data = JSON.stringify(postData.product_list)
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.$msg.topMessage(`${that.form.res_id ? '编辑' : '添加'}成功！`, 'success')
            that.close()
            that.$emit('refresh')
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .input-class {
    width: 45%;
  }
  .input-space {
    display: inline-block;
    width: 10%;
    text-align: center;
  }
</style>
