<template>
  <div>
    <el-table v-tableFit ref="table" :data="tableData" size="mini" border :height="height">
      <el-table-column type="index" align="center" fixed/>
      <el-table-column label="操作" align="center" width="80" fixed>
        <template slot-scope="scope">
          <el-button type="text" @click="openAdd(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
      <el-table-column v-for="(col, index) in columns" :prop="col.prop" :label="col.label" :key="index" header-align="center" show-overflow-tooltip :align="col.align?col.align:'left'" :width="col.width?col.width:''">
        <template slot-scope="scope">
          <el-tag v-if="col.prop == 'jump_way'" :type="tagList[scope.row[col.prop]].type">{{tagList[scope.row[col.prop]].name}}</el-tag>
          <el-image v-else-if="col.prop=='module_icon' && scope.row[col.prop]" fit="scale-down" class="table-img" :src="scope.row[col.prop]" :preview-src-list="[scope.row[col.prop]]" style="width: 54px; height: 30px;">
            <div slot="error" class="image-slot"><span/></div>
          </el-image>
          <span v-else>{{scope.row[col.prop]}}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination :page.sync="pagination.page_num" :size.sync="pagination.page_size" :total="pagination.total_num"/>
    <add ref="add" @refresh="loadData"/>
  </div>
</template>

<script>
  import add from './add'
  import mixinBaseIndex from '../../js/mixins/mixinBaseIndex'

  export default {
    name: 'diamondsSetting', // 金刚区设置
    components: { add },
    mixins: [ mixinBaseIndex ],
    data() {
      return {
        propName: {
          id: 'module_id'
        },
        postUrl: {
          list: '/module/list'
        },
        columns: [
          {prop: 'module_name', label: '模块标题'},
          {prop: 'module_desc', label: '模块描述'},
          {prop: 'module_icon', label: '模块icon'},
          {prop: 'jump_way', label: '跳转方式'},
          {prop: 'jump_url', label: '跳转地址'},
        ],
        tagList: {
          1: {name: '本小程序', type: 'success'},
          2: {name: '外部小程序', type: 'danger'},
          3: {name: '外部H5', type: 'warning'},
        },
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      loadData() {
        var that = this
        var url = that.postUrl.list
        var postData = {
          page_num: that.pagination.page_num,
          page_size: that.pagination.page_size,
        }
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.tableData = res.data
            that.pagination.total_num = res.total_num
          }
        })
      }
    }
  }
</script>
