<template>
  <el-dialog
    :title="title"
    :before-close="close"
    :visible.sync="dialog"
    @opened="open"
    append-to-body
    top="8vh"
    class="small-dialog overflow-dialog"
    width="640px"
    :close-on-click-modal="false">
    <el-form :model="form" ref="form" class="base-form" :label-width="label_width" :show-message="false">
      <el-form-item v-show="false" prop="advertisement_id"/>
      <el-form-item prop="advertisement_img" label="广告图片" required>
        <upload ref="upload1" :limit="1" @change="(file) => {changeImg(file, 1)}" @remove="(url) => {removeImg(url, 1)}"/>
      </el-form-item>
      <el-form-item prop="position" label="广告位置" required>
        <select-list ref="select1" :select_list="selectList" placeholder="请选择广告位置" :select_id.sync="form.position" :show_label="false" select_width="100%" margin_bottom="0" :clearable="false"/>
      </el-form-item>
      <el-form-item v-show="form.position==2" prop="play_time" label="播放时间" required>
        <el-input-number v-model.number="form.play_time" placeholder="请输入播放时间" :min="1" :precision="0" :controls="false"/>
      </el-form-item>
      <el-form-item v-show="form.position==2" prop="color" label="开机背景颜色" required>
        <el-color-picker v-model="form.color" show-alpha :predefine="predefineColors"/>
      </el-form-item>
<!--      <el-form-item v-show="form.position==1" prop="is_register" label="投放用户" required>-->
<!--        <el-radio-group v-model="form.is_register" class="radio-group-block">-->
<!--          <el-radio :label="2">所有会员</el-radio>-->
<!--          <el-radio :label="0">未注册会员</el-radio>-->
<!--          <el-radio :label="1">已注册会员</el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
      <el-form-item prop="type" label="广告类型" required>
        <select-list ref="select2" :select_list="selectList3" placeholder="请选择广告类型" :select_id.sync="form.type" :show_label="false" select_width="100%" margin_bottom="0" :clearable="false"/>
      </el-form-item>
      <el-form-item v-show="[1, 2, 3, 5].includes(form.type)" prop="jump_url" label="跳转地址" required>
        <el-input v-model="form.jump_url" placeholder="请输入跳转地址"/>
      </el-form-item>
      <el-form-item v-show="form.type==2" prop="app_id" label="小程序app_id" required>
        <el-input v-model="form.app_id" placeholder="请输入小程序app_id"/>
      </el-form-item>
      <el-form-item prop="date_range" label="广告日期" required>
        <time-range ref="timeRange" :select_time.sync="form.date_range" :show_label="false" width="100%" @change="changeRange"/>
      </el-form-item>
      <el-form-item prop="start_time" label="广告时间" required>
        <el-time-picker v-model="form.start_time" placeholder="开始时间" format="HH:mm:ss" value-format="HH:mm:ss" :clearable="false"/>
        <span class="p-l-r-14px">~</span>
        <el-time-picker v-model="form.end_time" placeholder="结束时间" format="HH:mm:ss" value-format="HH:mm:ss" :clearable="false"/>
      </el-form-item>
      <el-form-item prop="sort" label="广告排序" required>
        <el-input-number v-model.number="form.sort" placeholder="请输入广告排序" :min="1" :precision="0" :controls="false"/>
      </el-form-item>
      <el-form-item prop="remark" label="广告描述">
        <el-input type="textarea" v-model="form.remark" placeholder="请输入广告描述" :autosize="{minRows: 3}" maxlength="500" show-word-limit/>
      </el-form-item>
    </el-form>
    <submit-button @close="close" @save="save"/>
  </el-dialog>
</template>

<script>
  import upload from '../../components/tool/upload'
  import {formatDay} from '../../js/common/time'

  export default {
    name: 'addAdvert',
    components: {
      upload
    },
    data() {
      return {
        title: '',
        dialog: false,
        label_width: '110px',
        postUrl: {
          detail: '/advertisement/detail',
          add: '/advertisement/add',
          edit: '/advertisement/edit',
        },
        form: {
          advertisement_id: '',
          advertisement_img: '',
          imgList1: [],
          position: '', // 广告位置
          play_time: '', // 播放时间
          color: '',
          is_register: '', // 投放用户
          type: '', // 广告类型
          jump_url: '', // 跳转地址
          app_id: '', // 小程序app_id
          date_range: '',
          start_date: '',
          end_date: '',
          start_time: '',
          end_time: '',
          sort: '', // 排序
          remark: '', // 描述
        },
        imgProps: ['advertisement_img'],
        selectList: [
          {id: 1, name: '首页顶部banner'},
          {id: 2, name: '开机页'},
        ],
        selectList3: [
          {id: 1, name: '本小程序'},
          {id: 2, name: '外部小程序'},
          {id: 3, name: '外部H5'},
          {id: 4, name: '普通广告'},
          {id: 5, name: 'vue页面'},
        ],
        predefineColors: ['#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585']
      }
    },
    methods: {
      open() {
        if (this.form.advertisement_id) {
          this.title = '编辑广告'
          this.loadData()
        } else {
          this.title = '添加广告'
          this.form.position = 1
          this.form.type = 4
          // this.form.is_register = 2
          this.form.start_date = formatDay()
          this.form.end_date = formatDay()
          this.form.date_range = [this.form.start_date, this.form.end_date]
          this.form.start_time = '00:00:00'
          this.form.end_time = '23:59:59'
          this.$nextTick(() => {
            this.$refs.select1.init()
            this.$refs.select2.init()
          })
        }
      },
      close() {
        this.title = ''
        this.$refs.select1.clear()
        this.$refs.select2.clear()
        this.$refs.upload1.clear()
        this.$refs.form.resetFields()
        this.dialog = false
      },
      // 加载详情数据
      loadData() {
        var that = this
        var url = that.postUrl.detail
        var postData = {
          advertisement_id: that.form.advertisement_id,
        }
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.form = that.setField(that.form, res.data)
            that.form.date_range = [that.form.start_date, that.form.end_date]
            that.imgProps.forEach((item, index) => {
              if (that.form[item]) {
                that.form[`imgList${index + 1}`] = that.form[item].split(',')
                that.$refs[`upload${index + 1}`].initImg(that.form[`imgList${index + 1}`])
              }
            })
            that.$nextTick(() => {
              that.$refs.select1.init()
              that.$refs.select2.init()
            })
          }
        })
      },
      changeImg(file, type = 1) {
        this.form[`imgList${type}`] = this.deepCopy(file)
        this.form[this.imgProps[type - 1]] = this.form[`imgList${type}`].join()
      },
      removeImg(url, type = 1) {
        let index = this.form[`imgList${type}`].findIndex((val) => {
          return val === url
        })
        if (index !== -1) {
          this.form[`imgList${type}`].splice(index, 1)
          this.form[this.imgProps[type - 1]] = this.form[`imgList${type}`].join()
        }
      },
      // 筛选时间段
      changeRange(range) {
        this.form.date_range = this.deepCopy(range)
        this.form.start_date = this.form.date_range[0]
        this.form.end_date = this.form.date_range[1]
      },
      save() {
        var that = this
        if (!that.form.advertisement_img) return that.$msg.topMessage('请选择广告图片')
        if (that.form.position === '') return that.$msg.topMessage('请选择广告位置')
        if (that.form.position==2 && that.form.play_time === undefined) return that.$msg.topMessage('请输入播放时间')
        if (that.form.position == 2 && !that.form.color) return that.$msg.topMessage('请输入开机背景颜色')
        if (!that.form.type) return that.$msg.topMessage('请选择广告类型')
        if ([1, 2, 3, 5].includes(that.form.type) && !that.form.jump_url) return that.$msg.topMessage('请输入跳转地址')
        if (that.form.type == 2 && !that.form.app_id) return that.$msg.topMessage('请输入小程序app_id')
        // if (!that.form.start_date) return that.$msg.topMessage('请选择开始时间')
        // if (!that.form.end_date) return that.$msg.topMessage('请选择结束时间')
        if (that.form.sort === undefined) return that.$msg.topMessage('请输入广告排序')
        var url = that.form.advertisement_id ? that.postUrl.edit : that.postUrl.add
        var postData = that.deepCopy(that.form)
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.$msg.topMessage(`${that.form.advertisement_id ? '编辑' : '添加'}成功！`, 'success')
            that.close()
            that.$emit('refresh')
          }
        })
      }
    }
  }
</script>
