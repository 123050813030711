<template>
  <div>
    <div class="tool-bar">
      <add-button @add="openAdd"/>
    </div>
    <el-table v-tableFit ref="table" :data="tableData" size="mini" border :height="height">
      <el-table-column type="index" align="center" fixed/>
      <el-table-column label="操作" align="center" width="100" fixed>
        <template slot-scope="scope">
          <el-button type="text" @click="openAdd(scope.row)">编辑</el-button>
          <el-button type="text" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column v-for="(col, index) in columns" :prop="col.prop" :label="col.label" :key="index" header-align="center" show-overflow-tooltip :align="col.align?col.align:'left'" :width="col.width?col.width:''">
        <template slot-scope="scope">
          <el-image v-if="col.prop=='dish_style_img' && scope.row[col.prop]" fit="scale-down" class="table-img" :src="scope.row[col.prop]" :preview-src-list="[scope.row[col.prop]]" style="width: 54px; height: 30px;">
            <div slot="error" class="image-slot"><span/></div>
          </el-image>
          <span v-else>{{scope.row[col.prop]}}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination :page.sync="pagination.page_num" :size.sync="pagination.page_size" :total="pagination.total_num"/>
    <add ref="add" @refresh="loadData"/>
  </div>
</template>

<script>
  import add from './add'
  import mixinBaseIndex from '../../js/mixins/mixinBaseIndex'

  export default {
    name: 'cuisine', // 菜系管理
    components: { add },
    mixins: [ mixinBaseIndex ],
    data() {
      return {
        propName: {
          id: 'dish_style_id'
        },
        postUrl: {
          list: '/dish_style/list',
          del: '/dish_style/del'
        },
        columns: [
          {prop: 'dish_style_name', label: '菜系名称'},
          {prop: 'dish_style_img', label: '菜系图片'},
          {prop: 'created_at', label: '创建时间'}
        ]
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      loadData() {
        var that = this
        var url = that.postUrl.list
        var postData = {
          page_num: that.pagination.page_num,
          page_size: that.pagination.page_size,
        }
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.tableData = res.data
            that.pagination.total_num = res.total_num
          }
        })
      }
    }
  }
</script>
