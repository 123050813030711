<template>
  <div>
    <div class="tool-bar">
      <add-button @add="openAdd"/>
      <select-list ref="select" :select_list="selectList" label_name="状态" placeholder="请选择状态" :select_id.sync="status" select_width="140px" :clearable="false"/>
<!--      <search-input :keyword.sync="search_key" placeholder="广告名称"/>-->
      <text-button @clickBtn="query"/>
      <text-button type="danger" name="清除" @clickBtn="clearData"/>
    </div>
    <el-table v-tableFit ref="table" :data="tableData" size="mini" border :height="height">
      <el-table-column type="index" align="center" fixed/>
      <el-table-column label="操作" align="center" width="100" fixed>
        <template slot-scope="scope">
          <el-button type="text" @click="openAdd(scope.row)">编辑</el-button>
          <el-button type="text" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column v-for="(col, index) in columns" :prop="col.prop" :label="col.label" :key="index" header-align="center" show-overflow-tooltip :align="col.align?col.align:'left'" :width="col.width?col.width:''">
        <template slot-scope="scope">
          <el-image v-if="col.prop=='advertisement_img' && scope.row[col.prop]" fit="scale-down" class="table-img" :src="scope.row[col.prop]" :preview-src-list="[scope.row[col.prop]]">
            <div slot="error" class="image-slot"><span/></div>
          </el-image>
          <el-tag v-else-if="col.prop == 'status'" :type="tagList[scope.row[col.prop]].type">{{tagList[scope.row[col.prop]].name}}</el-tag>
          <span v-else-if="col.prop == 'type'">{{tagList2[scope.row[col.prop]].name}}</span>
          <span v-else-if="col.prop == 'position'">{{tagList3[scope.row[col.prop]].name}}</span>
          <span v-else-if="col.prop == 'start_date'">
            <div>{{scope.row[col.prop]}}~{{scope.row.end_date}}</div>
            <div>{{scope.row.start_time}}~{{scope.row.end_time}}</div>
          </span>
          <span v-else>{{scope.row[col.prop]}}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination :page.sync="pagination.page_num" :size.sync="pagination.page_size" :total="pagination.total_num"/>
    <add ref="add" @refresh="loadData"/>
  </div>
</template>

<script>
  import add from './add'
  import mixinBaseIndex from '../../js/mixins/mixinBaseIndex'

  export default {
    name: 'advert', // 广告管理
    components: { add },
    mixins: [ mixinBaseIndex ],
    data() {
      return {
        propName: {
          id: 'advertisement_id',
          status: 'status',
        },
        postUrl: {
          list: '/advertisement/list',
          del: '/advertisement/del',
        },
        columns: [
          {prop: 'status', label: '状态', width: 80, align: 'center'},
          {prop: 'advertisement_img', label: '广告图片'},
          {prop: 'type', label: '广告类型'},
          {prop: 'position', label: '广告位置'},
          {prop: 'start_date', label: '展示时间'},
          {prop: 'sort', label: '广告排序'},
          // {prop: 'picture_desc', label: '广告描述'},
          {prop: 'jump_url', label: '跳转地址'},
        ],
        status: 1,
        selectList: [
          {id: '', name: '全部'},
          {id: 0, name: '未开始'},
          {id: 1, name: '展示中'},
          {id: 2, name: '已过期'},
        ],
        tagList: {
          0: {name: '未开始', type: 'warning'},
          1: {name: '展示中', type: 'success'},
          2: {name: '已过期', type: 'danger'},
        },
        tagList2: {
          1: {name: '本小程序'},
          2: {name: '外部小程序'},
          3: {name: '外部H5'},
          4: {name: '普通广告'},
          5: {name: 'vue页面'},
        },
        tagList3: {
          1: {name: '首页顶部banner'},
          2: {name: '开机页'},
        },
      }
    },
    methods: {
      init() {
        this.$refs.select.init()
        this.loadData()
      },
      loadData() {
        var that = this
        var url = that.postUrl.list
        var postData = {
          status: that.status,
          page_num: that.pagination.page_num,
          page_size: that.pagination.page_size,
        }
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.tableData = res.data
            that.pagination.total_num = res.total_num
          }
        })
      },
      clearData() {
        this.status = 1
        this.$nextTick(() => {
          this.$refs.select.init()
        })
      }
    }
  }
</script>
