<template>
  <el-dialog
    :title="title"
    :before-close="close"
    :visible.sync="dialog"
    @opened="open"
    append-to-body
    top="8vh"
    class="small-dialog"
    width="450px"
    :close-on-click-modal="false">
    <el-form :model="form" ref="form" class="base-form" :label-width="label_width" :show-message="false">
      <el-form-item v-show="false" prop="scenes_id"/>
      <el-form-item v-show="false" prop="imgList1"/>
      <el-form-item prop="scenes_name" label="场景名称" required>
        <el-input v-model="form.scenes_name" placeholder="请输入场景名称"/>
      </el-form-item>
      <el-form-item prop="scenes_img" label="场景图片" required>
        <upload ref="upload1" :limit="1" @change="(file) => {changeImg(file, 1)}" @remove="(url) => {removeImg(url, 1)}"/>
      </el-form-item>
    </el-form>
    <submit-button @close="close" @save="save"/>
  </el-dialog>
</template>

<script>
  import upload from '../../components/tool/upload'

  export default {
    name: 'addScene',
    components: { upload },
    data() {
      return {
        title: '',
        dialog: false,
        label_width: '80px',
        postUrl: {
          add: '/scenes/add',
          edit: '/scenes/edit',
        },
        form: {
          scenes_id: '',
          scenes_name: '',
          scenes_img: '',
          imgList1: [],
        },
        imgProps: ['scenes_img'],
      }
    },
    methods: {
      open() {
        if (this.form.scenes_id) {
          this.title = '编辑场景'
          this.imgProps.forEach((item, index) => {
            if (this.form[item]) {
              this.form[`imgList${index+1}`] = this.form[item].split(',')
              this.$refs[`upload${index+1}`].initImg(this.form[`imgList${index+1}`])
            }
          })
        } else {
          this.title = '添加场景'
        }
      },
      close() {
        this.title = ''
        this.$refs.upload1.clear()
        this.$refs.form.resetFields()
        this.dialog = false
      },
      changeImg(file, type = 1) {
        this.form[`imgList${type}`] = this.deepCopy(file)
        this.form[this.imgProps[type - 1]] = this.form[`imgList${type}`].join()
      },
      removeImg(url, type = 1) {
        let index = this.form[`imgList${type}`].findIndex((val) => {
          return val === url
        })
        if (index !== -1) {
          this.form[`imgList${type}`].splice(index, 1)
          this.form[this.imgProps[type - 1]] = this.form[`imgList${type}`].join()
        }
      },
      save() {
        var that = this
        if (!that.form.scenes_name) return that.$msg.topMessage('请输入场景名称')
        if (!that.form.scenes_img) return that.$msg.topMessage('请选择场景图片')
        var url = that.form.scenes_id ? that.postUrl.edit : that.postUrl.add
        var postData = that.form
        that.$axios(url, postData).then(res => {
          if (res.status_code == 200 || res.status_code == 201) {
            that.$msg.topMessage(`${that.form.scenes_id ? '编辑' : '添加'}成功！`, 'success')
            that.close()
            that.$emit('refresh')
          }
        })
      }
    }
  }
</script>
